import React from 'react'
import ReactMarkdown from "react-markdown"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from 'gatsby'
// import Image from "gatsby-image"

import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import PhotoGallery from "../components/photoGallery"
import Heart from "../components/view/heart"
import Drawing from "../components/view/drawing"


const AboutTemplate = (props) => {
    // console.log("AlbumPageTemplate: ", props);
    // const { previous, next } = props.pageContext;
    const slug = props.data.page.slug;
    const fileNameRegExp = /\/([^/]+)\.\w{1,4}$/;
    const album = props.data.page;
    const cloudinaryImages = props.data.cloudinaryImages.nodes;

    let pictures = album.frontmatter.pictures.map((pic, index) => {
      const res = fileNameRegExp.exec(pic.image);
      if (!res || !res[1]) return undefined;
      const picData = cloudinaryImages.filter(pic => pic.public_id.includes(res[1]))[0];
      return {
        index: index,
        type: picData.type || "image",
        id: picData.id,
        src: picData.imgUrl,
        thumbnail: picData.thumb,
        w: picData.width,
        h: picData.height,
        title: pic.title || "",
        description: picData.description || "",
      };
    });
    
    const getThumbnailContent = (item) => {
      return (
        <div className="">
          <img src={item.thumbnail} alt={item.title} loading="lazy" className="vp-album-item__pic mb1"/>
          <h5 className="mb0">{item.title}</h5>
        </div>
      );
    }

    return (
      <section className={"page vp-about vp-lang-" + props.pageContext.language}>
        <SEO
          title={album.frontmatter.title}
          description={album.frontmatter.description}
        />
        <TopMenu smallLogo={true} lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation} slug={slug}></TopMenu>
        <div className="container-1200">
          <div className="section">
          <div className="vp-section-header columns aic mb1">
            <div className="column column-50 mr2">
              <div className="posr">
                <img src={album.frontmatter.image} className="" alt="me"></img>
                <div className="vp-about-drawing__heart1 color-accent-2">
                  <Drawing immediate={true} delay="1000"><Heart/></Drawing>
                </div>
                <div className="vp-about-drawing__heart2 color-accent-2">
                  <Drawing immediate={true} delay="1500" direction="bottom-right" ><Heart/></Drawing>
                </div>
              </div>
            </div>
            <div className="column column-50 posr">
              <h1 className="color-accent-2">{album.frontmatter.title}</h1>
              <ReactMarkdown source={album.frontmatter.description} linkTarget="_blank"/>
            </div>
          </div>
          <div className="vp-album-desc">
            <ReactMarkdown source={album.frontmatter.content} linkTarget="_blank"/>
          </div>
          {/* <div className="vp-album-header">
              <h3 className="vp-album-title color-accent-3">{album.frontmatter.title}</h3>
              <div className="vp-album-desc">
                <ReactMarkdown source={album.frontmatter.content} linkTarget="_blank"/>
              </div>
          </div>   */}
          {
            Boolean(pictures.length) && <PhotoGallery items={pictures} options={{}} thumbnailContent={getThumbnailContent}/>
          }
          </div>
        </div>
        <Footer lang={props.pageContext.language} languages={props.pageContext.languages} translation={props.pageContext.translation}></Footer>
      </section>
    )
  
}

export default AboutTemplate

export const indexQuery = graphql`
  query AboutById($id: String!, $language: String!) {
    page: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/about" }
        language: { eq: $language }
      }
    ) {
      slug
      fields {
        slug
      }
      frontmatter {
        language
        image
        title
        description
        content
        pictures {
          image
        }
      }
    }
    cloudinaryImages: allCloudinaryItem {
      nodes {
        id
        public_id
        thumb
        imgUrl
        width
        height
        orientation
      }
    }
  }
`